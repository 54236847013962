<template>
  <div class="container" id="translations_create">
    <h1 v-html="$t('translations_create_title')"></h1>
    <v-form @submit.prevent="onSubmit()">
      <v-row>
        <v-textarea id="search_translation_text_de" v-model="translation.textDe" :label="$t('search_translation_text_de')" type="text" @keyup.enter="search" />
        <v-textarea id="search_translation_text_fr" v-model="translation.textFr" :label="$t('search_translation_text_fr')" type="text" @keyup.enter="search" />
        <v-textarea id="search_translation_text_it" v-model="translation.textIt" :label="$t('search_translation_text_it')" type="text" @keyup.enter="search" />
        <v-text-field
          id="search_translation_message_key"
          v-model="translation.messageKey"
          :label="$t('search_translation_message_key')"
          type="text"
          @keyup.enter="search"
        />
      </v-row>
      <div class="aligned-buttons">
        <v-btn color="secondary" @click="goBack"><span v-html="$t('translation_button_back')" /></v-btn>
        <v-btn color="primary" type="submit"><span v-html="$t('translations_button_save')" /></v-btn>
      </div>
    </v-form>
  </div>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { apiURL } from '../../main'

const translationMinimal = {
  id: 0,
  textDe: '',
  textFr: '',
  textIt: '',
  messageKey: '',
  legacyMessageKey: ''
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'translation_create',
  data() {
    return {
      translation: translationMinimal
    }
  },
  methods: {
    async onSubmit() {
      this.translation.id = 0
      try {
        await this.axios.post(apiURL + '/translations/save', [...this.translation], { headers: { 'Content-Type': 'application/json' } })
      } catch (e) {
        showError(e)
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
})
</script>
<style lang="scss" scoped>
.aligned-buttons {
  display: flex !important;
  float: right;
}
</style>
